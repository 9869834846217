import React from 'react'
import { Head, Footer, Banner, Form, Newsletter } from '~/components'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { useApi } from '~/api'
import * as st from '~/assets/styl/Contato.module.styl'
import imgContato from '~/assets/img/img-banner-contato.jpg'
import imgContatoMobile from '~/assets/img/img-banner-contato-mobile.jpg'

const Contato = () => {
  const query = useStaticQuery(graphql`
    query {
      allWhatsApps {
        nodes {
          number
          name
        }
      }
    }
  `)

  const whatsData = useApi(
    { whatsapps: query.allWhatsApps.nodes },
    'configuration'
  )
  return (
    <>
      <Head />
      <main>
        <Banner
          largeTitle
          title="Ficou com
          alguma dúvida?"
          content="Fale conosco!"
          img={imgContato}
          mobile={imgContatoMobile}
        />
        <div className={st.core}>
          <section className="container">
            <h2>Entre em contato com a nossa equipe</h2>
            <p>
              Utilize o formulário e envie uma mensagem para nossos consultores.{' '}
            </p>
            <div>
              <ul>
                {whatsData.whatsapps?.map(({ number }, index) => (
                  <li key={index}>
                    <a
                      href={`https://api.whatsapp.com/send?phone=55${number.replace(
                        /[^0-9]/g,
                        ''
                      )}&text=Ol%C3%A1%21%20Visitei%20o%20site%20da%20Dimas%20Seguros%20e%20tenho%20interesse%20em%20saber%20mais.
                      `}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {number}
                    </a>
                  </li>
                ))}
              </ul>
              <section>
                <h3>Horário de atendimento</h3>
                <ul>
                  <li>Segunda a sexta | 9h às 18h</li>
                  <li>Sábados | 9h às 12h</li>
                </ul>
                <small>Exceto em feriados nacionais.</small>
              </section>
            </div>
            <Link
              target="__blank"
              to="http://www.dimas.com.br/trabalhe-conosco"
              className={st.work}
            >
              Trabalhe Conosco
            </Link>
            <Form textBtn="Enviar" />
          </section>
        </div>
        <Newsletter justDesk />
      </main>
      <Footer hasNewsletter inDesk />
    </>
  )
}

export default Contato
